


















































































































































































import WidgetMixins from '../../mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import { currencyFormat, loadWidget } from '@/utils/helpers';
import { namespace } from 'vuex-class';
import { IDialogAndButtonAction, IProduct, PayloadState } from '@/types/types';
import DialogMixins from '@/mixins/DialogMixins';
const productsModule = namespace('products');
@Component({
  name: 'Trash',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    SnackBar: loadWidget('widgets/SnackBar'),
    DeleteDialog: loadWidget('widgets/DeleteDialog'),
    ToolBar: loadWidget('widgets/ToolBar'),
  },
  filters: {
    currencyFormat,
  },
})
export default class Trash extends mixins(WidgetMixins, DialogMixins) {
  @productsModule.Getter('getTrashList') trashList!: IProduct;

  headers: Array<{ text: string; value: string }> = [
    {
      text: '',
      value: 'previewImage',
    },
    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'Amount',
      value: 'amount',
    },
    {
      text: 'Published',
      value: 'isPublished',
    },
    {
      text: 'Sold Out',
      value: 'isSold',
    },
    {
      text: 'Actions',
      value: 'actions',
    },
  ];

  clearTrash(payload: IDialogAndButtonAction<PayloadState>): void {
    this.$store.dispatch('products/clearTrash', payload);
  }
  created(): void {
    this.$store.dispatch('products/trash');
  }
}
